// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.main-content {
  margin-left: 230px;
  @media (max-width: 991px) {
    margin-left: initial;
  }
  .admin-checkPointEntryDetail {
    margin-top: 20px;
  }
}
.admin-login {
  .error-message {
    font-size: 10px;
    margin-bottom: 10px;
    color: red;
  }
}

.login-menu-container {
  display: flex;
  flex-direction: column;
  background: rgba(250, 250, 250, 0.7);
  margin-top: 20px;
  h2 {
    align-self: center;
    margin-top: 50px;
    color: #009fd5;
  }
  .content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-height: 35vh;
  }
  .button-container {
    width: 200px;
    button {
      margin-inline: auto;
      border-radius: 8px;
    }
  }
  .login-item {
    margin-inline: auto;
    cursor: pointer;
  }
  .admin-card {
    background-color: transparent;
  }
  .image-container {
    max-width: 150px;
    margin-bottom: 15px;
    margin-inline: auto;
  }
}
