.entrypoint {
  // background-color: #fff;

  .container-test {
    // max-width: 1140px;
    margin: 0 auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding: 0;
    @media (max-width: 768px) {
      padding: 10px 0;
    }
    // Header
    .header {
      // background-color: #f1f2f2;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      padding: 10px 20px 10px 20px;
      z-index: 1;
      @media (max-width: 600px) {
        padding: 0;
        justify-content: space-between;
        align-items: center;
        .left {
          h5 {
            font-size: 0.6rem;
            margin-bottom: 0;
          }
        }
        .center {
          p {
            font-size: 14px;
          }
        }
        .right {
          align-self: center;
          padding: 0;
        }
      }
      .left {
        padding: 0 0 1.5rem 0;
        @media (max-width: 768px) {
          padding: 0 0 1.5rem 0;
        }
        .highlight {
          color: #e0620c;
          font-weight: 800;
        }
      }
      .center {
        padding: 0 1rem;
        text-align: center;
        p {
          color: #1952d1;
          font-size: 17px;
          padding: 0;
          margin: 0;
          @media (max-width: 600px) {
            font-size: 13px;
          }
        }
        img {
          height: 5rem;
          width: auto;
          margin: 0 0 10px 0;
          @media (max-width: 600px) {
            max-height: 3rem;
          }
        }
      }
      .right {
        padding: 0 1rem 1.5rem 1rem;
        @media (max-width: 768px) {
          padding: 0 0 1.5rem 0;
        }
        img {
          max-height: 5rem;
          width: auto;
          padding: 0 1rem;
          @media (max-width: 600px) {
            max-height: 3rem;
          }
        }
        span {
          position: absolute;
          top: 45px;
          right: 10px;
          cursor: pointer;
          @media (max-width: 768px) {
            top: 0;
            right: -15px;
          }
        }
      }
    }
    //Body
  }
  .container.custome-container {
    max-width: 1300px;

    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 185px);
    // justify-content: space-between;
    padding: 0;
    // Header

    //Body
    .body {
      text-align: center;
      padding: 80px 0 40px 0;
      @media (max-width: 1024px) {
        padding: 25px 50px;
      }
      @media (max-width: 767px) {
        padding: 25px 12px;
      }
      // Welcome
      .welcome {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: calc(100vh - 301px);
        // .info {
        // }
        .guide-card {
          margin: 10px 0;
          padding: 10px 30px;
          border-radius: 0;
          background-color: #fff;
          border: 1px solid rgba(82, 77, 77, 0.5882352941);
          max-height: 235px;
          overflow: auto;
        }
        .right-input {
          justify-content: flex-end !important;
          @media (max-width: 991px) {
            justify-content: center !important;
          }
        }
        .insert {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 0;
          @media (max-width: 991px) {
            justify-content: center;
          }
          label {
            display: flex;
            justify-content: flex-start;
            font-size: 17px;
            font-weight: 600;
            color: #e0620c;
          }
          input {
            margin: 10px 0;
            padding: 10px 30px;
            border-radius: 0;
            border: 1px solid #524d4d96;
            border-radius: 8px;
            @media (max-width: 768px) {
              margin: 10px 0;
            }
          }
          a {
            // padding: 10px 0;
            button {
              background-color: #e0620c;
              border: transparent;
              transition: 0.3s all ease-in-out;
              color: #fff;
              padding: 10px 30px;
              // margin: 0 0 0 20px;
              font-size: 18px;
              border-radius: 8px;
            }
            button:hover {
              background-color: #a41120;
            }
            button:active {
              background-color: #45080f;
            }
          }
        }
        .text-one {
          margin-bottom: 30px;
          h5 {
            margin-bottom: 0;
          }
        }
        .text-two {
          margin-bottom: 30px;
        }
        .text-three {
          h3 {
            color: #e0620c;
            font-weight: 600;
          }
        }
        .text-four {
          margin-bottom: 10px;
          .highlight {
            color: #e0620c;
          }
          h5 {
            margin-bottom: 0;
          }
        }
        .text-five {
          margin-bottom: 10px;

          h3 {
            font-weight: 800;
          }
        }
      }
      // Register
      .registration {
        .card {
          .card-container {
            .card-details {
              .uid-input {
                color: transparent;
                background-color: transparent;
                // &:focus {
                //   border: solid 1px green;
                // }
              }
              // .radio-option {
              //   input {
              //     margin: 0 5px;
              //   }
              //   padding: 5px;
              //   display: flex;
              //   align-items: center;
              // }
              .country-code-section {
                position: relative;
                input {
                  padding: 0.375rem 0rem 0.375rem 3rem;
                }
                .country-code {
                  img {
                    position: absolute;
                    left: 24px;
                    top: 20%;
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    @media (max-width: 768px) {
                      top: 22%;
                    }
                  }
                  select {
                    position: absolute;
                    left: 12px;
                    width: 11%;
                    // opacity: 0;
                    color: transparent;
                    background-color: transparent;
                    z-index: 2;
                    padding: 0.375rem 0rem 0.375rem 0.25rem;
                    option {
                      color: #000;
                    }
                  }
                }
              }
            }
            .card-details.hide {
              display: none;
            }
          }
          .image-container {
            img {
              max-height: inherit;
              max-width: inherit;
              aspect-ratio: 1;
              object-fit: cover;
              object-position: center;
            }
          }
        }
        .passport-container {
          p {
            text-align: start;
            font-size: 12px;
            font-weight: 800;
            padding: 5px 0;
          }
          .image-container {
            min-height: 300px;
            min-width: 300px;
            max-height: 300px;
            // max-width: 300px;
            padding: 10px 0;
            img {
              max-height: inherit;
              max-width: inherit;
              object-fit: contain;
            }
          }
        }
      }
      // Submission
      .submission {
        .verify {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 70px;
          width: 70px;
          font-weight: 700;
          object-fit: contain;
          img {
            padding: 0 5px;
          }
        }
      }
      .camera {
        .card {
          width: 443px;
          // height: 236px;
          height: 500px;
          position: relative;
          .web-cam {
            position: absolute;
          }
        }
      }
    }
    // Footer
    .footer {
      z-index: 1;
      padding: 0;
      div {
        display: flex;
        justify-content: flex-end;
        background-color: #f1f2f2;
        font-size: 11px;
        font-weight: 600;
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
        a {
          color: #000;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}

.zoom-in {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  transition-property: color, background-color, border-color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-text-decoration-color,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.zoom-in,
.zoom-in:hover {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.zoom-in:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)),
    var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover {
    .option {
      visibility: visible;
      opacity: 1;
    }
  }
  .profile-image {
    img {
      max-height: 3rem !important;
    }
  }
  .option {
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 10%);
    padding: 10px;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    li {
      padding: 5px 0;
    }
  }
  .profile-text {
    p {
      font-size: 10px;
    }
    margin-left: 10px;
    margin-right: 10px;
  }
}

.registration {
  .card {
    width: 100%;
    background-color: #e5e5e5;
    border-radius: 8px;
    height: auto;
    border: none;
    border-radius: 0;
    position: relative;
    padding: 15px;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 13px;
    color: #545050;
    border-radius: 8px;
    .card-container {
      text-align: start;
      background-color: #e5e5e5;
      border-radius: 8px;
      padding: 20px;
      .card-details {
        // display: flex;
        align-items: center;
        padding: 5px 8px;
        label {
          font-size: 13px;
          font-weight: 500;
          // padding: 0 50px 0 0;
        }
        p {
          font-size: 14px;
          font-weight: 700;
        }
        .input-control {
          width: 100%;
          border: none;
          height: 39px;
          padding: 0.375rem 0.75rem;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 1.5;
          border-radius: 0;
          border-radius: 8px;
          @media (max-width: 768px) {
            margin: 10px 0;
          }
        }
        // .radio-option {
        //   input {
        //     margin: 0 5px;
        //   }
        //   padding: 5px;
        //   display: flex;
        //   align-items: center;
        // }
        // .country-code-section {
        //   position: relative;
        //   .country-code {
        //     img {
        //       position: absolute;
        //       right: 5%;
        //       top: 20%;
        //       width: 20px;
        //       height: 20px;
        //       object-fit: contain;
        //       @media (max-width: 768px) {
        //         top: 22%;
        //       }
        //     }
        //     select {
        //       position: absolute;
        //       right: 10px;
        //       width: 15%;
        //       opacity: 0;
        //       z-index: 2;
        //     }
        //   }
        // }
      }
      .card-details.hide {
        display: none;
      }
    }
    .image-container {
      min-height: 165px;
      min-width: 165px;
      max-height: 165px;
      max-width: 165px;
      padding: 0 5px;
      margin: 0 0 15px 0;
      text-align: center;
      label {
        font-size: 13px;
        font-weight: 500;
      }
      img {
        max-height: inherit;
        max-width: inherit;
        aspect-ratio: 1;
        object-fit: cover;
      }
    }
  }
  .passport-container {
    p {
      text-align: start;
      font-size: 12px;
      font-weight: 800;
      padding: 5px 0;
    }
    .image-container {
      // min-height: 300px;
      min-width: 300px;
      max-height: 300px;
      // max-width: 300px;
      padding: 10px 0;
      img {
        max-height: inherit;
        max-width: inherit;
        object-fit: cover;
      }
    }
  }
}
.entry-button {
  @media (max-width: 991px) {
    width: 100%;
    margin: 0 10px;
  }
}
.rotate {
  animation: animName 2s linear infinite;
}
@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
