.checkpoint-dashboard {
  margin-top: 20px;
  .header-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .btn-primary {
    &.export {
      width: 160px !important;
    }
    width: 160px;
  }
  .header {
    // text-align: right;
    margin-bottom: 25px;
  }
  h1 {
    color: #2b3990;
    font-size: 30px;
    font-weight: 700;
  }
  input {
    border: solid 1px #ccc;
    border-radius: initial;
  }
  .uid-input {
    color: transparent;
    &:focus {
      border: solid 1px green;
    }
  }
  .table-container {
    padding: 20px;
    background-color: #e5e5e5;
    border-radius: 8px;
    min-height: 70vh;
    overflow-x: auto;
    tr {
      td {
        .image-container {
          width: 30px;
          img {
            width: 25px !important;
            height: 25px !important;
            max-width: 25px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }
  .head-text {
    h4 {
      font-size: 25px;
      font-weight: 700;
      color: #009fd5;
    }
  }

  .content {
    color: red;
    .search-container {
      align-self: flex-end;
    }
  }
  .form-trail {
    margin-top: 25px;
    .input-container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        display: grid;
      }
    }
    .guide-input {
      .country-code {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 2px;
        right: 66%;
        @media (max-width: 1440px) {
          right: 64%;
        }
        @media (max-width: 1340px) {
          right: 62%;
        }
        @media (max-width: 1140px) {
          right: 58%;
        }
        @media (max-width: 768px) {
          right: 0;
        }
        .flag {
          width: 15px;
          height: 15px;
        }
      }
    }
    select {
      padding: 0 5px;
      width: 203px;
      max-width: 100%;
      border: none;
      height: 30px;
    }
    label {
      width: 170px;
    }
    .date-container {
      select {
        padding: 0 5px;
        width: auto;
        max-width: 100%;
        border: none;
        height: 30px;
      }
    }
  }
}
.search-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0 0 20px 0;
  input {
    border: solid 1px #ccc;
  }
  select {
    padding: 4px;
    border: 1px solid #ccc;
    background-color: white;
    &:focus-visible {
      outline: none;
    }
  }
}
.checkpoint-dashboard.entrypoint {
  margin-top: 20px;
  .back {
    margin-left: 22px;
  }
  .body {
    padding-top: 0;
  }
  .card-payment {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    padding: 5px 8px;
  }
  .tap-card {
    opacity: 0;
    width: 0;
  }
  .card-details {
    .col-md-8 {
      margin-left: -30px;
      @media (max-width: 1440px) {
        margin-left: 0;
      }
    }
  }
  .card-details.hide {
    display: flex !important;
  }
}
.search-container {
  display: flex;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    display: grid;
    width: 100%;
  }
  input {
    border: solid 1px #ccc;
    border-radius: 0;
    border-radius: 8px;
  }
  select {
    padding: 4px;
    border: 1px solid #ccc;
    background-color: white;
    &:focus-visible {
      outline: none;
    }
  }
}

.check-box {
  .input-control {
    height: initial !important;
  }
  .form-check {
    display: flex;
    align-items: center;
    gap: 5px;
    label {
      font-size: 14px !important;
      font-weight: 700 !important;
    }
    .form-check-input {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
}

.bg-color-pd {
  padding: 20px;
  background-color: #e5e5e5;
  border-radius: 8px;
  @media (max-width: 1024px) {
    .checkboxes {
      display: grid;
    }
  }
}

.customLanguageEdit {
  width: 203px;
  max-width: 100%;
}
