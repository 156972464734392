.trek-card-table {
  overflow-x: auto;
  overflow-y: hidden;
  // background-color: white;
  padding: 10px 10px 0px;
  height: 100%;
  .table-header {
    padding-left: 8px;
    margin-top: 7px;
    margin-bottom: 9px;
    span {
      p {
        font-size: 18px;
        font-weight: 700;
        color: #ed1c24;
      }
    }
  }
  p {
    color: #77a3d7;
    font-size: 12px;
  }
  Table {
    margin: initial;
    tbody {
      tr {
        cursor: pointer;
        td {
          padding: 5px 5px;
          .first-td {
            display: flex;
            align-items: center;
            column-gap: 4px;
            margin-top: 3px;
            .logo-container {
              width: 25px;
              height: 25px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 25px;
                height: 25px;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          .second-td,
          .third-td {
            min-height: 30px;
            display: flex;
            align-items: center;
          }
          .second-td {
            p {
              font-weight: 800;
              color: #009fd5;
            }
          }
          .third-td {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
tbody {
  tr {
    cursor: pointer;
  }
}
.height100 {
  min-height: 322px;
  max-height: 322px;
  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
  .trek-card-table {
    background-color: white;
  }
  .loader-container {
    min-height: 322px;
    max-height: 322px;
    position: relative;

    .loader {
      height: 100px;
      width: 100px;
      left: 40%;
      bottom: 40%;
    }
  }
}
.red {
  .trek-card-table {
    border-bottom: 10px solid #ed1c24;
    min-height: 322px;
    max-height: 322px;
    border-radius: 8px;
    &::-webkit-scrollbar {
      display: none;
    }
    .table-responsive {
      min-height: 260px;
      max-height: 260px;
      @media (max-width: 769px) {
        min-height: 240px;
        max-height: 240px;
        margin-bottom: 20px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: scroll;
    }

    .table-header {
      span {
        p {
          color: #ed1c24;
          min-width: 80px;
        }
        .pagination {
          .page-item {
            .page-link {
              padding: 0 0.6rem;
            }
          }
        }
      }
    }
    .pagination {
      .page-item {
        height: 20px;
        width: 20px;
        margin: 0px 4px;
      }
    }
  }
}
.yellow {
  .trek-card-table {
    border-bottom: 10px solid #f7941d;
    min-height: 322px;
    max-height: 322px;
    border-radius: 8px;
    &::-webkit-scrollbar {
      display: none;
    }
    .table-responsive {
      min-height: 310px;
      max-height: 310px;
      @media (max-width: 769px) {
        margin-bottom: 20px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      overflow-y: scroll;
    }
    .table-header {
      p {
        color: #f7941d;
      }
    }
    .pagination {
      .page-item {
        height: 20px;
        width: 25px;
        margin: 0px 3px;
      }
    }
  }
}

//user view detail table card
.user-detail-table {
  background-color: white;
  padding: 15px 0px 20px 10px;
  .table {
    tbody {
      tr {
        td {
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
