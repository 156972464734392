.admin-card.color-card {
  .card-content {
    p {
      color: white !important;
      font-size: 35px;
    }
  }
}

.admin-card.small-card {
  .card-content {
    p {
      margin-top: 0;
      color: #575756;
      font-size: 35px;
      font-weight: 600;
    }
  }
}
.admin-card {
  // padding: 20px 8px 10px 20px;
  padding: 15px;
  padding-bottom: 9px;
  background-color: white;
  // border-bottom: 10px solid green;
  svg {
    path {
      stroke: grey;
    }
  }
  .box-header {
    margin-bottom: 15px;
    h4 {
      font-weight: 700;
      color: #009fd5;
    }
    p {
      font-size: 18px;
      font-weight: 700;
      color: #009fd5;
    }
  }

  .card-content {
    margin-bottom: 50px;

    .left {
      flex-basis: 45%;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #575756;
      margin-top: -10px;
    }
    .text-visitor {
      margin-top: 15px;
      font-size: 40px;
      font-weight: 700;
      color: #575756;
    }
  }
}
.big-number {
  color: #575756 !important;
  font-size: 35px !important;
  font-weight: 600 !important;
}
.green-gradient {
  .admin-card {
    background: linear-gradient(to right, #3fd3a2, #3bb2b8) left bottom white
      no-repeat;
    background-size: 100% 10px;
    border-radius: 8px;
  }
}
.yellow-gradient {
  .admin-card {
    background: linear-gradient(to right, #f9be87, #f38181) left bottom white
      no-repeat;
    background-size: 100% 10px;
    border-radius: 8px;
  }
}
.darkPurple-gradient {
  .admin-card {
    background: linear-gradient(to right, #cd94c2, #984a9d) left bottom white
      no-repeat;
    background-size: 100% 10px;
    border-radius: 8px;
  }
}
.categoriesCard {
  background: linear-gradient(to right, #7ba7e7, #6078ea) left bottom white
    no-repeat;
  background-size: 100% 10px;
  border-radius: 8px;
  height: 100%;
  .card-content {
    margin-bottom: 10px;
    .detail-container {
      display: flex;
      // justify-content: space-between;
      gap: 15px;
      flex-wrap: wrap;
      .left {
        flex-basis: 22%;
        padding-right: 20px;
        border-right: 1px solid #f1f2f2;
      }
      .middle {
        padding-right: 20px;
        border-right: 1px solid #f1f2f2;
      }
      .header + .header {
        margin-top: 15px;
      }
      .header {
        p {
          font-size: 12px;
          font-weight: 500;
        }
        .bold {
          font-size: 12px;
          font-weight: 700;
          margin-top: 5px;
        }
      }
    }
  }
}
.color-card {
  border-bottom: initial;
  background-color: #ed1c24;
  padding-bottom: 4px;
  .box-header,
  .card-content,
  .box-footer {
    p {
      color: white;
    }
  }
}
.yellow {
  .color-card {
    background-color: #f7941d;
    border-radius: 8px;
  }
}
.red {
  .color-card {
    background-color: #ed1c24;
    border-radius: 8px;
  }
}

.small-card {
  border-bottom: initial;
  background-color: #e5e5e5;
  border-radius: 8px;
  padding-bottom: 4px;
  border-radius: 8px;
  .box-header {
    margin-bottom: 2px;
    p {
      font-size: 12px;
      font-weight: 800;
    }
  }
  .card-content {
    margin-bottom: 16px;
  }
  .box-footer {
    justify-content: space-between;
    align-items: initial;
    .left {
      margin: auto 0;
      p {
        font-size: 10px;
        font-weight: 700;
      }
    }
    .right {
      display: flex;
      column-gap: 5px;
      justify-content: flex-end;
      align-items: center;

      p {
        text-align: end;
        font-size: 12px;
        font-weight: 500;
      }
      .drop-down-logo {
        width: 8px;
      }
    }
  }
}

.card-row {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  justify-content: space-between;
  margin-bottom: 25px;
}
.colored-card {
  padding: 10px;
  color: white;
  flex-basis: 23%;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 991px) {
    flex-basis: 32%;
  }
  @media (max-width: 767px) {
    flex-basis: 48%;
  }
  .sm-title {
    p {
      font-size: 14px;
    }
  }
  h2 {
    font-weight: 700;
  }
}

.box-footer {
  display: flex;
  column-gap: 5px;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;
  color: #605b5b;
  p {
    text-align: end;
    font-size: 12px;
    font-weight: 500;
  }
  .drop-down-logo {
    width: 15px;

    svg {
      height: 5px;
    }
  }
}

.info-card-coponent {
  .card {
    width: 100%;
    background-color: #f1f2f2;
    height: auto;
    border: none;
    // border-radius: 17px;
    position: relative;
    padding: 15px;
    background-color: white;
    .card-container {
      text-align: start;
      .card-details {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        label {
          font-size: 13px;
          font-weight: 500;
          // padding: 0 50px 0 0;
        }
        p {
          font-size: 14px;
          font-weight: 700;
        }
        .input-control {
          width: 100%;
          border: none;
          height: 35px;
          padding: 0.375rem 0.75rem;
          font-size: 0.8rem;
          font-weight: 400;
          line-height: 1.5;
          border-radius: 0.375rem;
        }
        .radio-option {
          input {
            margin: 0 5px;
          }
          padding: 5px;
          display: flex;
          align-items: center;
        }
      }
    }
    .custome-flex {
      flex-wrap: wrap;
      column-gap: 5px;

      .image-container {
        width: 40%;
        min-width: 125px;
        max-width: 100%;
        // padding: 0 5px;
        margin: 0 0 15px 0;
        text-align: center;
        label {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.last-location-card {
  padding: 15px;
  background-color: white;
  height: 210px;
  .text-container {
    padding: 0 0 10px 0;
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .condition-text {
    padding: 0 0 10px 0;
    align-items: center;
    .bold {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    column-gap: 15px;
    margin-top: 10px;
    button {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.admin-checkpoint-card {
  background-color: #e5e5e5;
  border-radius: 8px;
  .box-header {
    display: flex;
    justify-content: space-between;
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    .right {
      p {
        font-size: 10px;
        margin-top: 6px;
      }
    }
  }
  .box-footer {
    justify-content: left;
    &.online {
      ul {
        color: green;
      }
    }
    ul {
      padding: initial;
      padding-left: 14px;
      color: red;
    }
  }
}

.userStattusCard {
  .body {
    ul {
      padding-left: 18px;
    }
  }
}

.checkPointFormCard {
  padding: 15px 0;
  background-color: #e5e5e5;
  border-radius: 8px;
  .input-group {
    label {
      display: flex;
      justify-content: space-between;
      input {
        width: 40px;
      }
    }
  }
}

.info-card {
  p {
    text-transform: capitalize;
  }
}

.history-card {
  background-color: #e5e5e5 !important;
  min-height: 150px;
  table {
    thead {
      color: #009fd5;
      font-weight: 500;
    }
    td {
      padding: 5px 0;
      text-transform: capitalize;
    }
  }
}

.flag-card {
  background-color: rgb(120, 129, 190);
  margin-bottom: 26px;
  border-radius: 8px;
  .flag-card-content {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-bottom: 20px;
    .logo-container {
      width: 56px;
    }
  }
}
